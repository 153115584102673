import { useForm, SubmitHandler } from 'react-hook-form';
import { LoginLink, SignupLink } from 'src/components/auth-link';
import { TextButton } from 'src/components/buttons/text-button';
import ControlledInputText from 'src/components/forms/elements/controlled-input-text';
import { useShowNotification } from 'src/hooks/use-notification';
import useSdk from 'src/hooks/use-sdk';
import useTranslate from 'src/hooks/use-translate';
import { trackEvent, useImpressionTracking } from 'src/tracking';
import { useTrackingContext } from 'src/tracking/tracking-context';
import { captureException } from 'src/utilities/exceptions';
import { isEmail } from 'src/utilities/validator';

type FormForgotPasswordProps = {
  onSubmitSuccess?: () => void;
};

type FormValues = {
  email: string;
};

/**
 * A form displayed when user has forgotten their password
 * the component can be displayed both on a static page or in a modal view.
 * In the modal case, you can override the internal links to Login and Signup
 * @component
 * @param onLoginClick - callback when user clicks on the Login link
 * @param onSubmitSuccess - callback when the password rest was submitted
 */

export default function ForgotPasswordForm({ onSubmitSuccess }: FormForgotPasswordProps) {
  const t = useTranslate();
  const sdk = useSdk();
  const { control, handleSubmit, formState } = useForm<FormValues>();

  const showNotification = useShowNotification();

  const trackingDisplayContext = useTrackingContext();

  // Track each time when the user sees the component
  const { impressionObserverRef } = useImpressionTracking({
    eventName: 'ForgotPasswordDisplayed',
    displayContext: trackingDisplayContext,
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await sdk.sendResetPasswordEmail({
        input: {
          email: data.email,
        },
      });
      showNotification({
        title: t('notification__password_reset_title'),
        text: t('notification__password_reset_text'),
        type: 'success',
        hideAfterSeconds: 15,
      });
      // track log-in success
      trackEvent({ eventName: 'ForgotPasswordSuccess', displayContext: trackingDisplayContext });
      // execute after-submit callback if provided
      onSubmitSuccess?.();
    } catch (error) {
      // handle a server error, although keep in mind that if user is not found,
      // there will be no error response to protect users privacy
      const message = error instanceof Error ? error.message : 'Password Reset failed';
      showNotification({
        title: t('notification__error_password_reset_title'),
        text: t('notification__error_password_reset_text'),
        type: 'error',
        hideAfterSeconds: 15,
      });
      trackEvent({
        eventName: 'ForgotPasswordError',
        message,
        displayContext: trackingDisplayContext,
      });
      // and log in our error tracking tool
      captureException(error);
    }
  };

  // define the form submit handler including the validation tracking for the analytics
  const formSubmitHandler = handleSubmit(onSubmit, (errors) =>
    trackEvent({
      eventName: 'ForgotPasswordError',
      errors,
      displayContext: trackingDisplayContext,
    }),
  );

  return (
    <div data-test="forgot-password-form" ref={impressionObserverRef}>
      <div className="mb-4">
        <h1 className="dg-text-medium-2 mb-8 text-center">{t('forgot_password__form_title')}</h1>
      </div>
      <form onSubmit={formSubmitHandler} className="mb-4 flex flex-col space-y-4" noValidate>
        <ControlledInputText
          label={t('forgot_password__email')}
          control={control}
          id="forgot_password__email"
          name="email"
          defaultValue=""
          rules={{
            required: t('forgot_password__error_email_required'),
            validate: { isEmail: (value) => isEmail(value) || t('forgot_password__error_email_wrong') },
          }}
          type="email"
          autoComplete="username"
        />

        <TextButton type="submit" data-test="button-forgot-password-submit" loading={formState.isSubmitting} fullWidth>
          {t('forgot_password__button')}
        </TextButton>
      </form>
      <div className="dg-text-regular-3 text-center">
        <div className="mb-4">
          <span className="opacity-75">{t('forgot_password__no_account')}</span>{' '}
          <SignupLink className="underline" trackingTextId="forgot_password__signup">
            {t('forgot_password__signup')}
          </SignupLink>
        </div>
        <div>
          <LoginLink className="underline" trackingTextId="forgot_password__login">
            {t('forgot_password__login')}
          </LoginLink>
        </div>
      </div>
    </div>
  );
}
