import UAParser from 'my-ua-parser';

/**
 * A helper to check if the browser can play the high bandwidth video track
 * @returns boolean
 */
export const canPlayHighBandwidthVideo = () => {
  // assume that all modern desktops and tablets can play the high-quality video track
  const testQuery = '(min-width: 768px)';
  // check if the browser supports the matchMedia API
  return typeof window !== 'undefined' && window.matchMedia && window.matchMedia(testQuery).matches;
};

/**
 * A helper to check if the browser supports the Dolby Atmos audio track
 * @returns boolean
 */
export const canPlayDolbyAtmos = () => {
  // no need to check on the server
  if (typeof document === 'undefined') {
    return false;
  }
  // MS Edge does support Dolby Atmos, but only in mp4/m4a containers which we use only in album track manifests
  // Our VoD concert atmos and 4k video segments are in .cmfv containers which Edge does not support
  // @todo [2026-01-20] remove this restriction in case we have migrated away
  // from .cmfv segments and all our atmos and 4k video segments are now .mp4
  if (new UAParser().getBrowser().name?.includes('Edge')) {
    return false;
  }
  // check if the browser supports the Dolby Atmos audio track on a video element
  const video = document.createElement('video');
  // check the docs on following pages
  // https://developer.dolby.com/platforms/html5/browser-support/
  // https://developer.dolby.com/tools-media/utilities/browser-feature-detection/
  const dolbyAtmosCodec = 'video/mp4;codecs="ec-3"';
  return video.canPlayType(dolbyAtmosCodec) === 'probably' || video.canPlayType(dolbyAtmosCodec) === 'maybe';
};

/**
 * Check if the browser can play HLS stream natively
 * @param videoElRef the video element reference
 * @returns boolean if the HLS can be played natively
 */
export const canPlayNativeHls = (): boolean => {
  const videoElement = document.createElement('video');
  const nativeHlsType = 'application/vnd.apple.mpegurl';
  return videoElement?.canPlayType(nativeHlsType) === 'maybe';
};
