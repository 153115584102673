import { useForm } from 'react-hook-form';
import { TextButton } from 'src/components/buttons/text-button';
import ControlledInputCheckbox from 'src/components/forms/elements/controlled-input-checkbox';
import ControlledInputText from 'src/components/forms/elements/controlled-input-text';
import Modal from 'src/components/modal';
import { useSubscriptionConfirmedModal } from 'src/hooks/use-modal';
import useSdk, { ValidationError } from 'src/hooks/use-sdk';
import useTranslate from 'src/hooks/use-translate';
import { useImpressionTracking, useNewsletterTracking } from 'src/tracking';
import { TrackingContextValues } from 'src/tracking/tracking-context';

type SubscriptionConfirmedInput = {
  firstName: string;
  lastName: string;
  sendNewsletter: boolean;
};

/**
 * Renders a modal that is displayed after the user has paid for a subscription.
 * It asks the user to provide their first name and last name, and to opt in to the newsletter.
 */
function SubscriptionConfirmedModalContent() {
  const t = useTranslate();
  const sdk = useSdk();
  const { control, handleSubmit, formState, setError } = useForm<SubscriptionConfirmedInput>();
  const { close } = useSubscriptionConfirmedModal();
  const { onNewsletterSuccess } = useNewsletterTracking('DG');
  const { impressionObserverRef, trackEvent } = useImpressionTracking({ eventName: 'PostSubscriptionFormImpression' });

  async function onSubmit(values: SubscriptionConfirmedInput) {
    try {
      const updateUserMutation = await sdk.updateUser({ input: values });
      const errors = updateUserMutation?.updateUser?.errors;
      const validationErrors = errors?.filter((error): error is ValidationError => 'field' in error);

      if (!errors?.length) {
        // track if user agreed to newsletter
        if (values.sendNewsletter) {
          onNewsletterSuccess();
        }
        // track that the form was submitted
        trackEvent({
          eventName: 'PostSubscriptionFormSubmit',
        });
        // and we can close the modal now
        close();
      } else if (validationErrors?.length) {
        // if there are validation errors, we need to set them on the form
        for (const error of validationErrors) {
          if (error.field === 'firstName' || error.field === 'lastName') {
            setError(error.field, {
              message: t('signup__form_error_field_wrong'),
            });
          }
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        trackEvent({
          eventName: 'SignupError',
          method: 'Email',
          message: error.message,
          displayContext: TrackingContextValues.SubscriptionConfirmedModal,
        });
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={impressionObserverRef} className="flex flex-col gap-8" noValidate>
      <h1 className="dg-text-medium-2 text-center">{t('subscription_confirmed__title')}</h1>
      <p className="dg-text-regular-3 text-center text-white text-opacity-70">{t('subscription_confirmed__subline')}</p>

      <fieldset className="flex flex-col gap-4">
        <ControlledInputText
          label={t('form__first_name_label')}
          control={control}
          name="firstName"
          autoComplete="given-name"
          defaultValue=""
          rules={{
            // Require first name if user wants to receive newsletter
            validate: (value, formValues) =>
              formValues.sendNewsletter && value.toString().trim().length === 0
                ? t('signup__error_no_firstname')
                : undefined,
          }}
        />
        <ControlledInputText
          label={t('form__last_name_label')}
          control={control}
          name="lastName"
          autoComplete="family-name"
          defaultValue=""
          rules={{
            // Require last name if user wants to receive newsletter
            validate: (value, formValues) =>
              formValues.sendNewsletter && value.toString().trim().length === 0
                ? t('signup__error_no_lastname')
                : undefined,
          }}
        />
        <ControlledInputCheckbox
          label={t('subscription_confirmed__form_newsletter_label')}
          defaultValue={false}
          control={control}
          name="sendNewsletter"
        />
      </fieldset>
      <TextButton type="submit" loading={formState.isSubmitting} fullWidth>
        {t('subscription_confirmed__button_cta')}
      </TextButton>
    </form>
  );
}

export default function SubscriptionConfirmedModal() {
  const { isOpen, close } = useSubscriptionConfirmedModal();
  return (
    <Modal open={isOpen} onClose={close} dataTest="subscription-confirmed-modal">
      {/* render the modal content only if we actually have the modal open */}
      <Modal.Content>
        <SubscriptionConfirmedModalContent />
      </Modal.Content>
    </Modal>
  );
}
