import { useCallback } from 'react';
import SmallChevronIcon from '@stageplus/icons/react/small-chevron';
import { useForm, SubmitHandler } from 'react-hook-form';
import { TextButton } from 'src/components/buttons/text-button';
import LegalLinks from 'src/components/legal-links';
import Translate from 'src/components/translate';
import { useShowNotification } from 'src/hooks/use-notification';
import useSdk from 'src/hooks/use-sdk';
import useTranslate from 'src/hooks/use-translate';
import { useNewsletterTracking } from 'src/tracking';
import { isEmail } from 'src/utilities/validator';

type FormValues = {
  email: string;
};

/**
 * The Newsletter form for the footer
 * for subscribing to the general Deutsche Gramophone newsletter
 */
export default function FooterNewsletterForm() {
  const t = useTranslate();
  const sdk = useSdk();
  const showNotification = useShowNotification();

  const { register, handleSubmit, reset, setError, formState } = useForm<FormValues>();

  const { onNewsletterStart, onNewsletterValidationErrors, onNewsletterSuccess } = useNewsletterTracking('DG');

  const onSubmit = useCallback<SubmitHandler<FormValues>>(
    async (data) => {
      const input = { email: data.email };
      try {
        await sdk.subscribeDgNewsletter({ input });
        // show success message
        showNotification({
          title: t('notification__newsletter_subscribe_success_title'),
          text: t('notification__newsletter_subscribe_success_text'),
          type: 'success',
          hideAfterSeconds: 8,
        });
        reset();
        // track the event in analytics
        onNewsletterSuccess();
      } catch {
        setError('email', { message: t('footer__newsletter_error') });
      }
    },
    [reset, sdk, setError, showNotification, t, onNewsletterSuccess],
  );

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onNewsletterValidationErrors)}
      data-test="footer-newsletter-form"
      // disable the native form validation, it is handled by the react form hook anyway
      noValidate
    >
      <div className="mb-5 flex flex-col space-y-3 md:flex-row md:space-y-0">
        <div className="grow md:mr-2">
          <div className="flex flex-col gap-2">
            <label className="sr-only" htmlFor="newsletter-email">
              {t('footer__newsletter_email')}
            </label>
            <input
              type="email"
              id="newsletter-email"
              placeholder={t('footer__newsletter_email')}
              autoComplete="email"
              className="dg-text-regular-6 inline-block h-11 w-full rounded-md bg-white/10 px-4 py-3 focus-visible:focus-outline lg:h-[52px]"
              {...register('email', {
                required: t('validation__email_required'),
                validate: { isEmail: (value) => isEmail(value) || t('validation__email_invalid') },
              })}
              onFocus={onNewsletterStart}
            />
            {formState.errors.email && (
              <div className="dg-text-regular-8 text-errorOrangeC6" data-test="input-email-error">
                {formState.errors.email.message}
              </div>
            )}
          </div>
        </div>
        <div className="inline-block">
          <TextButton
            type="submit"
            loading={formState.isSubmitting}
            variation="newsletter"
            data-test="newsletter-form-submit"
            fullWidth
          >
            {t('footer__newsletter_button')}
            {<SmallChevronIcon className="inline-block" aria-hidden style={{ verticalAlign: '-7px' }} />}
          </TextButton>
        </div>
      </div>
      <div className="w-full grow">
        <span className="dg-text-regular-6 relative -top-1 inline-block select-none text-white text-opacity-70 2xl:leading-snug">
          <Translate
            i18nKey="footer__newsletter_policy"
            components={[
              <LegalLinks.Privacy
                className="text-white underline outline-offset-2 focus-visible:focus-outline"
                key="privacy-link"
              />,
            ]}
          ></Translate>
        </span>
      </div>
    </form>
  );
}
